import {BloodSourceType} from '@matchsource/models/source';

export enum DonorSortCriteria {
  TenOfTen = 'TEN_OF_TEN',
  EightOfEight = 'EIGHT_OF_EIGHT',
}

export const DEFAULT_DONORS_CRITERIA = DonorSortCriteria.TenOfTen;
export enum SearchResultsMode {
  Classic = 'classic',

  AlleleReveal = 'allele',
}

export enum ExportType {
  Range = 'range',

  Selection = 'selection',

  All = 'all',
}

export interface ExportParams {
  fileFormat: 'pdf' | 'csv';
  columns?: {
    columns: string;
  };
  selectionType: ExportType;
  range?: {
    fromRef: number;
    toRef: number;
  };
  selection?: {
    selection: number[];
  };
}

export const SEARCH_EXPORT_TYPES = {
  [BloodSourceType.Donors]: 'DONOR_SEARCH_CSV_EXPORT_PREF',
  [BloodSourceType.AbOnly]: 'DONOR_SEARCH_CSV_EXPORT_PREF',
  [BloodSourceType.Cords]: 'CORD_SEARCH_CSV_EXPORT_PREF',
};
export const DPB1_MATCH_GRADE = {
  MIS: 'MIS',
  PER: 'PER',
  MAT: 'MAT',
  NON: 'NON',
  POT: 'POT',
};
export const DPB1_MATCH_GRADE_VALUE_MAP = {
  [DPB1_MATCH_GRADE.MIS]: '',
  [DPB1_MATCH_GRADE.PER]: 'Permissive',
  [DPB1_MATCH_GRADE.MAT]: 'Match',
  [DPB1_MATCH_GRADE.NON]: 'Non-permissive',
  [DPB1_MATCH_GRADE.POT]: 'Potential',
};
